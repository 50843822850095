<script>
import Layout from '../../layouts/main';
import { api } from '@/state/services';
import { required } from 'vuelidate/lib/validators';
import { VMoney } from 'v-money';

export default {
  locales: {
    pt: {
      'Donate': 'Doar',
      'Choose an entity to make a donation.': 'Escolha uma entidade para fazer uma doação.',
      'Choose a value': 'Escolha um valor',
      'Or enter the amount of your donate': 'Ou digite o valor da sua doação',
      'Value is required.': 'Valor é obrigatório',
      'New Donate': 'Nova Doação',
      'Rules': 'Regras',
      'The minimum donate is R$ 1,00.': 'O valor mínimo para doação é de R$ 1,00.',
      'The value should be a multiple of R$ 5,00.': 'O valor deve ser um múltiplo de R$ 5,00.',
    },
    es: {
      'Donate': 'Donar',
      'Choose an entity to make a donation.': 'Elija una entidad para hacer una donación.',
      'Choose a value': 'Elija un valor',
      'Or enter the amount of your donate': 'Ou introduzca la cantidad de su donación',
      'Value is required.': 'Se requiere valor.',
      'New Donate': 'Nueva Donar',
      'Rules': 'Normas',
      'The minimum donate is R$ 1,00.': 'El mínimo es donar R$ 1,00.',
      'The value should be a multiple of R$ 5,00.': 'El valor debe ser un múltiplo de R$ 5,00.'
    }
  },
  components: {
    Layout
  },
  data() {
    return {
      entities: {
      },

      loading: {
        entities: true,
        donation: false,
      },

      donation: {
        alert: { type: '', message: '' },
        entity: {
          url: '',
          name: '',
          logo: '',
        },
        value: '0,00',
      },

      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2
      },
    };
  },
  directives: { money: VMoney },
  validations: {
    donation: {
      value: { required },
    }
  },
  methods: {
    getEntity() {
      api
        .get('entity',)
        .then(response => {
          if (response.data.status=='success') {
            this.donation.entity.name = response.data.default.name
            this.donation.entity.logo = response.data.default.logo
          }
        })
    },
    setEntity(entity) {
      if (entity) {
        this.donation.entity.url = entity
        this.donation.entity.name = this.entities[entity].name
        this.donation.entity.logo = this.entities[entity].logo
      } else {
        this.donation.entity.url = ""
        this.donation.entity.name = ""
        this.donation.entity.logo = ""
      }
    },
    setDonation(donation) {
      this.donation.value = donation
    },
    depositSubmit() {
      this.$v.$touch();

      if (this.donation.value) {
        this.loading.donation = true

        api
          .post('wallet/deposit', {
            value: this.donation.value,
          })
          .then(response => {
            if (response.data.status=='success') {
              this.donation.value = '0,00'
              this.$v.$reset()

              this.donation.alert.type = 'alert-success'
              this.donation.alert.message = response.data.message

              this.$router.push('/wallet/order/' + response.data.order.id);
            } else {
              this.donation.alert.type = 'alert-danger'
              this.donation.alert.message = response.data.message
            }

            this.loading.donation = false
          })
      }
    }
  },
  mounted() {
    this.getEntity()

    if (this.$route.params.url) {
      this.setEntity(this.$route.params.url)
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('Donate') }}</h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-8">
        <div class="card">
          <div class="card-body p-4">
            <div v-if="donation.entity.name" class="p-3 text-dark font-size-15 text-left d-flex justify-content-between">
              <div class="bg-white p-2" v-if="donation.entity.logo"><img style="max-height:50px;" :src="donation.entity.logo" /></div>
              <div class="bg-white p-2" v-else><img style="max-height:50px;" src="@/assets/images/logo-icon.png" /></div>
              <span class="flex-fill px-3 align-self-center">{{ donation.entity.name }}</span>
            </div>
            <b-form class="p-3" @submit.prevent="depositSubmit">
              <div v-if="donation.alert.message" :class="'alert ' + donation.alert.type">{{ t(donation.alert.message) }}</div>
              <p class="font-weight-medium">{{ t('Choose a value') }}</p>
              <div class="mb-4">
                <a class="btn btn-danger text-white mr-2 mb-2" @click="setDonation('R$ 1,00')">R$ 1,00</a>
                <a class="btn btn-danger text-white mr-2 mb-2" @click="setDonation('R$ 5,00')">R$ 5,00</a>
                <a class="btn btn-danger text-white mr-2 mb-2" @click="setDonation('R$ 10,00')">R$ 10,00</a>
                <a class="btn btn-danger text-white mr-2 mb-2" @click="setDonation('R$ 20,00')">R$ 20,00</a>
                <a class="btn btn-danger text-white mr-2 mb-2" @click="setDonation('R$ 30,00')">R$ 30,00</a>
                <a class="btn btn-danger text-white mr-2 mb-2" @click="setDonation('R$ 50,00')">R$ 50,00</a>
                <a class="btn btn-danger text-white mr-2 mb-2" @click="setDonation('R$ 100,00')">R$ 100,00</a>
              </div>
              <b-form-group id="value" :label="t('Or enter the amount of your donate')" label-for="value">
                <b-form-input id="value" v-model="donation.value" v-money="money" type="text" inputmode="decimal" :class="{ 'is-invalid': $v.donation.value.$error }"></b-form-input>
                <div v-if="$v.donation.value.$error" class="invalid-feedback">
                  <span v-if="!$v.donation.value.required">{{ t('Value is required.') }}</span>
                </div>
              </b-form-group>
              <div class="mt-4">
                <b-button :disabled="loading.donation == true || this.donation.value==='R$ 0,00'" type="submit" variant="danger">
                  {{ t('New Donate') }}
                  <b-spinner v-if="loading.donation" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
                </b-button>
              </div>
              <div class="mt-4 small">
                {{ t('Rules') }}<br>
                <ul class="pl-4 mb-0">
                  <li>{{ t('The minimum donate is R$ 1,00.') }}</li>
                  <li>{{ t('The value should be a multiple of R$ 5,00.') }}</li>
                </ul>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>